.preview{
  width: 50vw;
  max-width: 200px;
  height: 50vh;
  max-height: 200px;
  object-fit: cover;
  padding-top: 20px;
  padding-bottom: 0px;
}

.chatbot-form-container{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  // margin-top: 50px;
  align-items: center;
  // height: 100vh;
  // max-height: 600px;
  width: 95vw;
  max-width: 550px;
  border-radius: 5%;
  h2{
    font-weight: 400;
    font-size: 1.5rem;
  }
}
.chatbot-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  width: 100%;
  height: 100%;
}

.chatbot-form input[type='text'], .chatbot-form textarea{
  width: 100%;
  border: 1px solid rgb(186, 186, 186, 0.814);
  padding: 16px 12px 10px 12px;
  font-family: Arial, Helvetica, sans-serif;
}
.chatbot-form input[type='submit']{
  background-color: var(--purple);
  width: 90%;
  color: white;
  padding: 5px;
  cursor: pointer;
  margin-top: 30px;
  border-radius: 2px;
  font-size: 1rem;
}

.chatbot-img-remove-button{
  background-color: red;
  width: 15%;
  color: white;
  padding: 5px;
  cursor: pointer;
  margin-top: 30px;
  border-radius: 2px;
  border: 1px solid black;
}


.chatbot-form input[type='submit']:hover{
  box-shadow: 0px 0px 2px rgba(12,1,1,0.6)
}


.chatbot-form input[type='text']:hover, .chatbot-form textarea:hover{
  border: 1px solid var(--purple);
}
.chatbot-form input[type='text']:focus, .chatbot-form textarea:focus{
  border: 1px solid var(--purple);
  outline: 1px solid var(--purple);
}


.input-container{
  position: relative;
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: 90%;
  padding: 20px 0px 10px 0px;
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: rgb(174, 173, 173);
  font-size: 14px;
  line-height: 1;
  left: 10px;
  top: 12px;
}

.input-container:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: black;
}
#filled{
  transform: translate(0, 12px) scale(0.8);
  color: black;
}