
:root{
    --purple: #541ffb;
    --black: rgb(49, 49, 49);
    --white: #FFFFFF
}


.app-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    height: 100vh;
}




.main-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    // top: 20vh;
    width: 100%;
    // height: 50vh;
    height: 100%;

}
  
.splash-header{
    font-size: 50px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    align-items: center;
    // margin-top: 210px;
    margin-left: 10vw;
    
}

.splash-header-container{
    margin-top: 100px;
}

h1.second{
    // margin-left: 40px;
}

h1.third{
    // margin-left: 60px;
}

.splash-content{
    display: flex;
    // justify-content: space-between;
    height: 100%;
    width: 100vw;
    // margin-top: 100px;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    // padding: 0px 20px;
    background-image: url("/assets/chatbots.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    // -webkit-mask-image: -webkit-gradient(linear, left bottom, center bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)));

}
.splash-img {
    
    width: 100%;
    // width: 1300px;
    // height: 85vh;
    border-radius: 1%;
    // object-fit: cover;
    
    
}



