.chatbot-show-profile{
  display: flex;
  flex-direction: column;
  align-items: center;
  li{
    font-size: 30px;
  }
}
.chatbot-show-img{
  border-radius: 5%;
  object-fit: cover;
  height: 200px;
  width: 200px;

}
.chatbot-show-box{
  // width: 400px;
  width: 95vw;
  max-width: 700px;
  // overflow-y: scroll;
  font-size: 18px;
  // height: 80vh;
  // margin-bottom: 2vh;
  ul{
    line-height: 150%;
    // height: 100px;
  }
  ul div{
    // padding-bottom: 20px;
    padding-left: 55px;
    padding-right: 15px;
   
  }
  ul > div{
     padding-top: 10px;
  }
  
}


.popup-button, .prompt-suggestions-regen-button{
  background-color: var(--purple);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  width: 100%;
  align-self: center;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 1rem;
}

.prompt-suggestions-regen-button{
  width: 75%;
  align-items: center;
}

.popup-button:hover{
  box-shadow: 0px 0px 2px rgba(12,1,1,0.6);
}

.popup-button:active{
  box-shadow: inset 0px 0px 8px rgba(12,1,1,0.5);
}

.typing{
  // width: 125px;
  // height: 75px;
  width: 150px;
  padding: 10px;
  // object-fit: cover
}

.show-chat-form{
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 20px;
  // margin-left: 10px;
  margin-right: 5px;
  input[type=text]{
    width: 60vw;
    margin-right: 5px;
    max-width: 600px;
  }
  // input[type=text]{
  //   width: 60vw;
    
  // }
}

.chatbot-show-details{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  position: relative;
}

.chat-header{
  font-weight: bolder;
  font-size: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 5px;
}

.show-chat-popup-container{
  // width:500px;
  // height:500px;
  // margin:0 auto;
  background:transparent;
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // margin-left:-250px;
  // margin-top:-250px;
  
}

.show-chat-popup{
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 400px;
  min-height: 300px;
  // height: 40vh;
  // max-height: 500px;
  border: 4px solid var(--purple);
  // width: 95vw;
  // max-width: 550px;
  border-radius: 1%;
}

.prompt-menu-wrapper{
  margin-top: 25px;
}

.back-button{
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  color: var(--black);
  font-size: 1.5rem;
  transition: all 750ms;
  transition-timing-function: ease-out;
}
.back-button:hover{
  color: var(--purple);
  transform: translateY(-2px);
}

.prompt-suggestions-container{
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: 500px;
  
}

.prompt-suggestions{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    max-height: 400px;
    overflow-y: auto;
}

.prompt-entry{
  margin: 5px;
  width: 75%;
  // margin-left: 10px;
  // text-indent: 10px;
  strong{
    position: relative;
    top: 2px;
  }
}
.prompt-entry:hover{
  cursor: pointer;
  color: var(--purple)
      // margin-right: 10px;
}

.prompt-loading-img{
  width: 200px;
  position: relative;
  top: -20px;
  margin-bottom: 25px;
}


.show-chat-popup-navigation{
  display: flex;
  justify-content: center;
  width: 100%;
  // position: relative;

  h1{
    font-weight: 700;
    font-size: 1.2rem;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
#show-chat-popup-x{
   cursor: pointer;
   top: 8px;
   right: 7px;
}
.show-chat-popup-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  button{
    cursor: pointer;
    width: 75%;
  }
}

.chatbot-show-img-small{
  border-radius: 50%;
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.chatbot-show-message-detail{
  display: flex;
  align-items: center;
  width: 100%;
  h1{
    position: relative;
    top: 15px;
    left: -125px;
    font-weight: 600;
    white-space: pre;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }
  img{
    margin-right: 20px;
    position: relative;
    top: 30px;
    left: -110px;
  }
}

.chatbot-show-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // top: 15vh;
  // top: 100px;
  // overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  // height: 75vh;
  height: 100%;
  // margin-bottom: 100px;
  margin-bottom: 75px;

}

.chatbot-show-message-form-container{
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  position: fixed;
  // height: 10vh;
  // height: 100px;
  z-index: 9;
  bottom: -5px;
  width: 100%;
  background-color: white;
  // height: 10vh;
  height: 50px;
}

// @media screen and (max-width: 480px) {
//   .navbar{
//     height: 15vh;
//   }
//   .chatbot-show-container{
//     height: 75vh;
//     margin-bottom: 50px;
//     // top: 15vh;
//   }
//   .chatbot-show-message-form-container{
//     // height: 10vh;
//     // height: 100px;

//     input[type=text]{
//       width: 60vw;
//       max-width: 700px;
//     }
//   }
// }

.chatbot-show-response{
  margin-bottom: 25px;
}

.chatbot-conformation-popup{
  display: flex;
  flex-direction: column;
  background-color: white;
  // height: 12vh;
  font-size: 1.5rem;
  padding: 40px 30px 30px 30px;
  width: 90vw;
  max-width: 700px;
  h1{
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    strong{
      font-weight: 700;
    }
  }
}
.chatbot-conformation-popup-buttons{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  button{
    width: 115px;
    font-size: 1.5rem;
    border-radius: 5px;
    padding: 3px;
    text-align: center;
  }
  button:hover{
    cursor: pointer;
  }
  .red-button{
    background-color: red;
    color: white;
  }
  .red-button:hover{
    background-color: darkred;
  }
  .green-button{
    background-color: green;
    color: white;
  }
  .green-button:hover{
    background-color: darkgreen;
  }
}


.show-chat-form-input{
  width: 100%;
  max-width: 1300px;
  font-size: 1.1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.show-chat-form-input:focus{
  outline: 1px solid var(--purple);
}

.show-chat-form-input:hover{
  border: 2px solid var(--purple);

}

.chat-form-button{
  background-color: transparent;
  color: var(--purple);
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  top: 4px;
  border: none;
  // width: 50px;
  // align-self: center;
  // vertical-align: middle;
  // display: table-cell;
  padding: 5px;
  cursor: pointer;
  // margin-top: 5px;
  border-radius: 10px;
  font-size: 1.75rem;
  transition: all 750ms;
  transition-timing-function: ease-out;
}

.chat-form-button:hover{
  transform: translateY(-2px);
  // box-shadow: 0px 0px 2px rgba(12,1,1,0.6);
}
.chat-form-button:hover:disabled{
  transform: none;
  // box-shadow: 0px 0px 2px rgba(12,1,1,0.6);
}

.chat-form-button:active{
  // box-shadow: inset 0px 0px 8px rgba(12,1,1,0.5);
}
.chat-form-button:disabled{
  color: #b9a5fc;
}