

#icon-404{
    padding-top: 50px;
    text-align: center;
    font-size: 25rem;
}

#message-404{
    text-align: center;
    margin-top: 100px;
    font-size: 1.5rem;
}