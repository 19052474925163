/* TODO Add site wide styles */
*{
    box-sizing: border-box
}
@import "./assets/stylesheets/reset.css";
@import "./assets/stylesheets/splash_page.scss";
@import "./assets/stylesheets/session_forms.css";
@import "./assets/stylesheets/chatbot_index.css";
@import "./assets/stylesheets/nav_bar.css";
@import "./assets/stylesheets/chatbot_search.css";
@import './assets/stylesheets/chatbot_new.scss';
@import './assets/stylesheets/chatbot_show.scss';
@import './assets/stylesheets/modal.css';
@import './assets/stylesheets/profile.scss';
@import './assets/stylesheets/footer.scss';
@import './assets/stylesheets/not-found.css';






