:root{
  --swiper-navigation-size: 50px !important;
  --swiper-theme-color: var(--purple) !important;
  --swiper-scrollbar-border-radius: 10px;
  --swiper-scrollbar-top: auto;
  --swiper-scrollbar-bottom: 1px;
  --swiper-scrollbar-left: auto;
  --swiper-scrollbar-right: 4px;
  --swiper-scrollbar-sides-offset: 1%;
  --swiper-scrollbar-bg-color: rgba(0, 0, 0, 0.1);
  --swiper-scrollbar-drag-bg-color: var(--purple);
  --swiper-scrollbar-size: 7px;
  /* --swiper-navigation-size: 100px; */
  --swiper-navigation-top-offset: 50%;
  --swiper-navigation-sides-offset: 0px;
  --swiper-navigation-color: var(--purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}


.swiper-slide {
  width: 125px !important;
}

.swiper {
  /* position: relative;
  top: 10vh; */
  /* width: 100vw; */
  /* padding-right: 100px; */
}
.swiper-wrapper{
  /* width: 1200px; */
}

div.swiper-button-prev{
  font-weight: 900;
 }
 /* div.swiper-button-prev::after{
   content: none;
   background-image: url('../arrow.jpg');
 } */
 
 div.swiper-button-next {
   font-weight: 900;
 }


.chatbots-index-container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  /* position: relative; */
  /* margin-top: 90px; */
  /* top: 80px; */
  max-width: 1200px;
  width: 100%;
  /* height: 80vh; */
  /* height: 100%; */
  /* max-height: 850px; */

  padding-left: 20px;
  padding-right: 20px;
  /* padding-bottom: 110px; */
  /* width: 600px; */
}
.featured-container, .chatted-container{
  /* display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* margin-left: 50px; */
  width: 100%;
  padding-bottom: 30px;
  /* width: 100vw; */
  /* max-width: 1200px; */

}

.created-container{
  width: 100%;
  padding-bottom: 60px;
}

.no-chatbot-message{
  height: 100px;
}

.chatbots-index-container h1{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--black);
  padding: 10px 0px 10px 0px;
}

  
.chatbots-index-details{
  padding: 5px;
  display: flex;
  flex-direction: column;
  flex: 0 1 125px;
  height: 200px;
  width: 125px;
  justify-content: space-evenly;
  margin-bottom: .5rem;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.217);
  border-radius: 5px;
  /* margin: .5rem; */
  position: relative;
  transition: all 750ms;
  transition-timing-function: ease-out;
}
.chatbots-index-details:hover{
  transform: translateY(-5px);
}
  
  
.chatbots-index-details img{
  height: 135px;
  width: 100%;
  object-fit: cover;
  object-position: 50% 0%;
}

.chatbots-index-details > li{
  color: var(--black);
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
  white-space: pre;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 14%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding: 5px;
  /* z-index: 5; */
}

#chat-button{
  background-color: var(--purple);
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  width: 100%;
  align-self: center;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 1rem;
}

#chat-button:hover{
  box-shadow: 0px 0px 2px rgba(12,1,1,0.6);
}

#chat-button:active{
  box-shadow: inset 0px 0px 8px rgba(12,1,1,0.5);
}

#resume-button{
  display: block;
  background-color: transparent;
  color: var(--purple);
  font-family: Arial, Helvetica, sans-serif;
  /* border: none; */
  width: 100%;
  align-self: center;
  padding: 5px;
  cursor: pointer;
  margin-top: 5px;
  border-radius: 5px;
  font-size: 1rem;
  border: 1px solid var(--purple);
  transition: all 750ms;
  transition-timing-function: ease-in;
  text-align: center;
}

#resume-button:hover{
  box-shadow: 0px 0px 2px rgba(12,1,1,0.6);
  transition: all 1s;
  background-color: var(--purple);
  color: white;
}

#resume-button:active{
  box-shadow: inset 0px 0px 8px rgba(12,1,1,0.5);
}

#create-button{
  border: 5px solid var(--purple);
  height: 120px;
  /* width: 120px; */
  border-radius: 50%;
  align-self: center;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  /* background-color: aqua; */
}



.loading-img{
  position: relative;
  top: 25vh;
}


/* @media screen and (max-width: 480px) {

  .chatbots-index-container{
    top: 125px;
    padding-bottom: 10px;

  }
} */

