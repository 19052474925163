.navbar{
  width: 100%;
  max-width: 1300px;
  /* height: 100px; */
  /* height: 15vh; */
  display: flex;
  justify-content: space-between;
  /* position: fixed; */
  position: sticky;
  padding: 20px 0px;
  top: 0;
  background-color: white;
  z-index: 5;
}

#chat-logo-title{
  display: flex;
  transition: all;
  transition: all 750ms;
  transition-timing-function: ease-out;
}

#chat-logo{
  color: var(--purple);
  font-size: 2rem;
  position: relative;
  top: 3px;
  padding-right: 5px;
}

#chat-title{
  font-family: Arial, Helvetica, sans-serif;
  color: var(--purple);
  font-size: 2rem;
  font-weight: 900;
}

#logout-button, #battle-button, #search-button{
  border: none;
  background-color: transparent;
  color: var(--purple);
  cursor: pointer;
  transition: all;
  transition: all 750ms;
  transition-timing-function: ease-out;
}

#profile-button{
  transition: all;
  transition: all 750ms;
  transition-timing-function: ease-out;
}

#logout-button:hover, #battle-button:hover, #search-button:hover, #profile-button:hover, #chat-logo-title:hover{
  transform: translateY(-2px);
}
#battle-button{
  font-size: 1.75rem;
}

#search-button{
  font-size: 1.75rem;
}

#logout-button{
  font-size: 1.75rem;
}



#create-button.nav-create-button{
  height: 60px;
  width: 60px;
  position: absolute;
  top: 80px;
  right: 50px;
  cursor: pointer;
  opacity: .5;
}

#create-button > div{
  color: var(--purple);
  font-size: 2rem;
  transition: all 1s;

  /* background-color: aqua; */
}

#create-button:hover{
  transition: all 1s;
  background-color: var(--purple);
  opacity: 1;
}


#create-button:hover > div{
  transition: all 1s;

  color: white;
}



.links-auth, .links-nav{
  display: flex; 
  width: 250px;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 20px;
}

.profile-pic{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.logo{
  height: 50px;
  width: 50px;
}


.nav-left{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content:flex-start;
  padding-left: 20px;
}

.nav-right{
  width: 50%;
  display: flex;
  justify-content:flex-end ;
  padding-right: 20px;
}

.links-auth{
  font-family: Arial, Helvetica, sans-serif;
  font-size: .75rem;
  color: var(--black);
 

}

.links-auth > a{
  transition: all 750ms;
  transition-timing-function: ease-out;
}

.links-auth > a:hover{
  color: var(--purple);
  transform: translateY(-2px);
  
}

