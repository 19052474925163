.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.modal-child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  display: flex;
  flex-direction: row-reverse;
  border-radius: .5%;
}

.close-x{
  position: absolute;
  font-size: 1.75rem;
  font-weight: 900;
  /* margin-top: 1vw;
  margin-right: 1vw; */
  cursor: pointer;
  top: 15px;
  right: 15px;
  transition: all 750ms;
  transition-timing-function: ease-out;
}
.close-x:hover{
  color: var(--purple);
  transform: translateY(-2px);
}