footer{
    display: flex;
    justify-content: center;
    margin: none;
    position: fixed;
    z-index: 9;
    left: 0px;
    bottom: 0px;
    height: 100px;
    width: 100%;
    background-color: var(--purple);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: white;
    h1{
        color: white;
    }
}

.footer-container{
    width: 100vw;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-footer{
    padding-left: 20px;
    display: flex;
    flex-direction: column;

}

.center-footer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.center-footer h1{
    padding-top: 5px;
}

.center-footer h1, .right-footer a, .left-footer a, .left-footer h1, .right-footer h1{
    color: white;
    text-align: center;
}

.right-footer{
    padding-right: 20px;
}

.footer-links{
    display: flex;
    justify-content: space-around;
}

.right-footer a, .left-footer a{
    font-size: 2.5rem;
    // padding: 10px 20px 10px 0px;
    padding-top: 5px;
    transition: all 750ms;
    transition-timing-function: ease-out;
}
.right-footer a:hover, .left-footer a:hover{
    transform: translateY(-5px); 
}

@media screen and (max-width: 480px) {
    .right-footer a, .left-footer a {
      padding: 10px 10px 10px 0px;
      font-size: 2rem;
    }
    footer{
    font-size: .75rem;
    }
    // footer{
    //    height: 50px; 
    // }
  }